import request from '@/utils/request'

//get 请求
/* 获取行政班列表数据 */
export const list = (page = 1, grade_id, class_name) => {
  return request({
    method: 'get',
    url: '/administration/list?page=' + page + '&grade_id=' + grade_id + '&class_name=' + class_name
  })
}

// 行政班添加
export const add = (data) => {
  return request({
    method: 'post',
    url: 'administration/add',
    data
  })
}

// 行政班添加
export const details = (data) => {
  return request({
    method: 'post',
    url: 'administration/details',
    data
  })
}

// 行政班添加
export const administrationEdit = (data) => {
  return request({
    method: 'post',
    url: '/administration/edit',
    data
  })
}


// 行政班添加
export const administrationDetails = (data) => {
  return request({
    method: 'post',
    url: '/administration/details',
    data
  })
}

// 行政班类型列表获取---公共
export const administrative = () => {
  return request({
    method: 'get',
    url: 'setup/administrative'
  })
}

// 行政班类型列表获取---公共
export const administration = (id) => {
  return request({
    method: 'get',
    url: 'administration/class?school_id=' + id
  })
}

/**
 * get
 * 参数---无
 * 获取校区列表
 */

export const siteSchool = () => {
  return request({
    method: 'get',
    url: 'site/school'
  })
}

/**
 * get
 * 参数---无
 * 获取校区列表
 */

export const teacher = (wd, school_id) => {
  return request({
    method: 'get',
    url: 'administration/teacher?wd=' + wd + '&school_id=' + school_id
  })
}