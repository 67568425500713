<template>
	<edit-template class="role-edit" ref="edit" @confirm="save('form')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: 500rem">
				<el-form-item label="班级名称" prop="class_name">
					<el-input v-model="form.class_name" placeholder="请输入班级名称"></el-input>
				</el-form-item>

				<el-form-item label="校区" prop="school_id">
					<el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" style="width: 220rem" @change="campusChange">
						<el-option v-for="(item, index) in campusArr" :key="item" :label="item" :value="index"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="班主任" prop="teacher_id">
					<el-autocomplete v-model="teacher" :fetch-suggestions="querySearchAsync" placeholder="请输入内容" @select="handleSelect" value-key="teacher_name" @change="handleChange"></el-autocomplete>
				</el-form-item>

				<el-form-item label="年级" prop="grade_id">
					<el-select :popper-append-to-body="false" v-model="form.grade_id" placeholder="请选择年级" style="width: 220rem">
						<el-option v-for="item in listData" :key="item.value" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="行政班类型" prop="administrative_type_id">
					<el-select :popper-append-to-body="false" v-model="form.administrative_type_id" placeholder="请选择行政班" style="width: 220rem">
						<el-option v-for="item in listDataTo" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="人数限制" required="">
					<div style="display: flex">
						<el-form-item prop="people_min">
							<el-input placeholder="请输入" v-model.number="form.people_min" type="number" :min="0" style="width: 100rem"> </el-input>
						</el-form-item>
						<span style="margin: 0 2rem">至</span>
						<el-form-item prop="people_max">
							<el-input placeholder="请输入" v-model.number="form.people_max" type="number" :min="0" style="width: 100rem"> </el-input>
						</el-form-item>
					</div>
				</el-form-item>

				<el-form-item label="教室" prop="class_room_id">
					<el-cascader v-model="form.class_room_id" :options="schoolList" placeholder="请选择教室"></el-cascader>
				</el-form-item>
			</el-form>
		</div>
		<!-- 底部按钮 -->
		
	
</edit-template>
</template>

<script>
import { administration, administrationDetails, administrationEdit, teacher } from './api'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			checkData: [],
			form: {},
			campusArr: [],
			listData: [],
			listDataTo: [],
			options: [],
			loading: false,
			teacher: null,
			schoolList: [],
			rules: {
				class_name: [
					{ required: true, message: '请输入班级名称', trigger: 'blur' },
					{ min: 1, max: 15, message: '仅限15字内的数字和汉字', trigger: 'blur' }
				],
				grade_id: [{ required: true, message: '请选择年级', trigger: 'change' }],
				teacher_id: [{ required: true, message: '请选择班主任', trigger: 'change' }],
				school_id: [{ required: true, message: '请选择校区', trigger: 'change' }],
				administrative_type_id: [{ required: true, message: '请选择行政班类型', trigger: 'change' }],
				people_min: [{ required: true, message: '请输入最小人数', trigger: 'blur' }],
				people_max: [{ required: true, message: '请输入最大人数', trigger: 'blur' }],
				class_room_id: [{ required: true, message: '请选择教室', trigger: 'change' }]
			},
			id: ''
		}
	},

	methods: {
		handleChange() {
			this.teacher = ''
			this.form.teacher_id = ''
		},
		querySearchAsync(val, callback) {
			teacher(val, this.form.school_id).then(res => {
				callback(res.data.data)
			})
		},
		handleSelect(val) {
			this.form.teacher_id = val.id
			this.form.teacher_name = val.teacher_name
		},
		campusChange(value) {
			this.form.administrative_type_id = ''
			this.form.class_room_id = ''
			this.form.teacher_id = ''
			this.form.grade_id = ''
			this.teacher = ''
			this.id = value
			administration(value).then(res => {
				this.listData = res.data.data.grade
				this.listDataTo = res.data.data.AdministrativeType
				this.schoolList = JSON.parse(
					JSON.stringify(res.data.data.buliding)
						.replace(/dimicile_buliding_name/g, 'label')
						.replace(/storey/g, 'children')
						.replace(/class_room/g, 'children')
						.replace(/name/g, 'label')
						.replace(/floor/g, 'label')
						.replace(/id/g, 'value')
						.replace(/bulvalueing_value/g, 'value')
				)
			})
		},
		save(form) {
			this.$refs[form].validate(valid => {
				if (valid) {
					if (this.form.people_min < 0 || this.form.people_max < 0) {
						this.$message.error('人数不能为负数')
					} else {
						if (this.form.people_min > this.form.people_max) {
							this.$message.error('最大人数不能小于最小人数')
						} else {
							delete this.form.teacher_name
							administrationEdit(this.form).then(res => {
								if (res.data.error.errorCode != 0) {
									this.$notify.error({
										title: '编辑失败',
										message: res.data.error.errorUserMsg
									})
								} else {
									this.$notify({
										title: '编辑成功',
										message: '编辑成功',
										type: 'success'
									})
									setTimeout(() => {
										this.$router.back()
									}, 0)
								}
							})
						}
					}
				} else {
					this.$message.error('您输入的信息有误或未填写，请检查后重新提交')
					return false
				}
			})
		},
		open() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		}
	},

	created() {
		// this.campusArr = this.$store.getters.userInfo.school_arr
		this.$utils.commonApi.getSchoolList().then(res => {
			this.campusArr = res
		})
		administrationDetails({ id: this.$route.query.id }).then(res => {
			this.form = res.data.data
			this.form.teacher_id = [this.form.teacher_id]
			this.form.people_min = Number(this.form.people_min)
			this.form.people_max = Number(this.form.people_max)
			this.form.class_room_id = this.form.low
			this.id = this.form.school_id
			teacher(this.form.teacher_name, this.form.school_id).then(res => {
				if (res.data.data.length > 0) {
					this.options = res.data.data
					this.teacher = res.data.data[0].teacher_name
					this.form.teacher_id = res.data.data[0].id
				} else this.form.teacher_id = ''
			})
			administration(this.form.school_id).then(res => {
				this.listData = res.data.data.grade
				this.listDataTo = res.data.data.AdministrativeType
				this.schoolList = JSON.parse(
					JSON.stringify(res.data.data.buliding)
						.replace(/dimicile_buliding_name/g, 'label')
						.replace(/storey/g, 'children')
						.replace(/class_room/g, 'children')
						.replace(/name/g, 'label')
						.replace(/floor/g, 'label')
						.replace(/id/g, 'value')
						.replace(/bulvalueing_value/g, 'value')
				)
			})
		})
	}
}
</script>

<style lang="scss" scoped>
</style>
